import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ChatInputForm from "../Components/ChatInputForm";
import ChatMessage from "../Components/ChatMessage";
import NewChatButton from "../Components/NewChatButton";
import Hero from "../Components/Hero";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";
import MessageHistory from "../Components/MessageHistory";
import BugReportModal from "../Components/BugReportModal";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { ref, push, get, set, update, increment } from "firebase/database";
import StoreButton from "../Components/StoreButton";
import "./MainPage.css";
import Disclaimer from "../Components/Disclaimer";
import TemperatureSlider from "../Components/TemperatureSlider";
import SignUpHeading from "../Components/SignUpHeading";
import NotSubscribedHeading from "../Components/NotSubscribedHeading";
import FreeTrial from "../Components/FreeTrial";
import ClearConversations from "../Components/ClearConversations";
import RateLimitError from "../Components/RateLimitError";
import ResponseFailedError from "../Components/ResponseFailedError";
import ModeChanger from "../Components/ModeChanger";
import CodingModeGuide from "../Components/CodingModeGuide";
import localStorage from 'localStorage'
import { makeStyles } from '@mui/styles';
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import React from 'react';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';



import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

type Anchor = 'top' | 'left' | 'bottom' | 'right';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  listItemAvatar: {
    minWidth: 'auto',
  },
  productTitle: {
    fontWeight: 'bold',
  },
  productPrice: {
    fontWeight: 'bold',
  },
}));

function MainPage({ app, db }) {
  if (!localStorage.getItem('country')) {
    var requestUrl = "https://api.ipregistry.co/?hostname=true&key=xccw2jpgul5onn8a";
    fetch(requestUrl)
    .then((response) => response.json())
    .then((json) => {
      let currentCountry = json.location.country.code;
      let foundCountry = ['US', 'AU', 'BR', 'CA', 'CN', 'FR', 'DE','IN', 'IT', 'MX', 'NL', 'SG', 'ES', 'TR', 'AE', 'GB', 'JP'].includes(currentCountry)
      if (foundCountry) {
        localStorage.setItem('country', json.location.country.code)
        localStorage.setItem('currency', json.currency.code)
      } else {
        localStorage.setItem('country', 'US')
        localStorage.setItem('currency', 'USD')
      } 
    });
  }

  const provider = new GoogleAuthProvider(app);
  const auth = getAuth(app);
  const firestoreDB = getFirestore(app);

  const [user, setUser] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [hasTrial, setHasTrial] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [responseFailed, setResponseFailed] = useState(false);
  const [selectedModel, setSelectedModel] = useState("text-davinci-003");
  const [bestProducts, setBestProducts] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


    const list = (anchor) => (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );

  function handleNewChat() {
    if (user) {
      setConversationId(user.uid + Date.now());
    }
    clearInput();
    clearChat();
    setIsRateLimited(false);
    setResponseFailed(false);
    setBestProducts([]);
    setTopProducts([]);
    setProducts([]);
  }

  // Redirect user to google login
  function handleLogin() {
    setIsLoggingIn(true);
    signInWithPopup(auth, provider);
    getRedirectResult(auth)
      .then(() => {
        console.log("Signed In");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Log user out, clear old messages and input
  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Logged out");
        clearInput();
        clearChat();
        setIsLoading(false);
        setIsRateLimited(false);
        window.location = "/"
        localStorage.removeItem('login')
        localStorage.removeItem('country')
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Check if a user has had a free trial and create one in database if they haven't
  const handleTrialPeriod = (user) => {
    const userRef = ref(db, `trials/${user.uid}`);

    get(userRef).then((snapshot) => {
      if (snapshot.val() === null) {
        // create a new user document with the createdAt timestamp
        set(userRef, {
          createdAt: Date.now(),
        })
          .then(() => {
            console.log("Trial Started");
            checkTrialExpired(user);
          })
          .catch((error) => {
            console.log("The write failed...", error);
          });
      }
    });
  };

  // Checks if user has free trial time
  const checkTrialExpired = async (user) => {
    if (!user) return;

    const userRef = ref(db, `trials/${user.uid}`);

    get(userRef).then((snapshot) => {
      if (snapshot.val() === null) return;

      const trialData = snapshot.val();
      const createdAt = trialData.createdAt;
      const now = Date.now();

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const trialDuration = now - createdAt;

      // Return true if the trial has not yet expired (is less than one day old)
      // if (trialDuration < oneDayInMilliseconds) {
      if (true) {
        setHasTrial(true);
      }
    });
  };

  useEffect(() => {
    // When the authentication state changes (user logging in/out)
    onAuthStateChanged(auth, (user) => {      
        setAuthLoading(false);
        // Update user
        setUser(user);
        // Give new conversation id
        
        setConversationId(user.uid + Date.now());  
        
        // Load users sub data
        loadSubscription();
        // Create free trial if user has never had one
        handleTrialPeriod(user);
        // Check if free trial is still active
        checkTrialExpired(user);
    });
  }, []);

  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  // Default temperature is 0.5 (must match temperature sliders default value/100)
  const [temperature, setTemperature] = useState(0.5);

  // Set temperature to 0 when user swaps to codex model and 0.5 when swapping back
  useEffect(() => {
    setTemperature(selectedModel === "code-davinci-002" ? 0 : 0.5);
  }, [selectedModel]);

  function clearChat() {
    setChatLog([]);
  }

  function clearInput() {
    setInput("");
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (user) {
      setConversationId(user.uid + Date.now());
    }

    clearChat();
    setIsRateLimited(false);
    setResponseFailed(false);
    setBestProducts([]);
    setTopProducts([]);
    setProducts([]);

    // Create reference to user's chat log in the database
    const chatRef = ref(db, `messages/${user.uid}/${conversationId}`);
    // Create reference to user's token usage in the database
    const tokenRef = ref(db, `token-usage/${user.uid}`);
    // Add user's input to the chat log
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];

    // Check if the user is logged in
    if (user) {
      // Push the user's input to the database
      push(chatRef, {
        user: "me",
        message: input,
        timestamp: Date.now(),
      })
        .then(() => {})
        // Log the error if the write failed
        .catch((error) => {
          console.log("The write failed...", error);
        });
    }

    // clearInput(); // Clear the input field
    setChatLog(chatLogNew); // Set the new chat log with user's input
    setIsLoading(true);

    // Give the AI the last 5 messages for context as well as the recent input
    const lastSix = chatLogNew.slice(Math.max(chatLogNew.length - 6, 0));
    const messages = lastSix.map((message) => message.message).join("\n");
    const apiURL = "https://zjeolwdjow-rimvrjej3q-uc.a.run.app";
    // var apiURL = "http://localhost:4000";
    const minB = '0';
    const maxB = '1000000';

    // Fetch response from the backend
   const response = await fetch(
    `${apiURL}/api/v1/search?query=${input}&country=${localStorage.getItem('country')}&minB=${parseFloat(minB).toFixed(2)}&maxB=${parseFloat(maxB).toFixed(2)}`
    ).catch((error) => {
      console.log(error)
      // Show response failed error
      setResponseFailed(true);
      setIsLoading(false);
      throw new Error("API response was not okay");
    });

    setIsLoading(false);
    // If user hit rate limit display error
    if (response.status === 429) {
      setIsRateLimited(true);
      setIsLoading(false);
      throw new Error("Rate limit reached");
    }

    // Response was successful
    setIsRateLimited(false);
    setResponseFailed(false);

    // Get the response data in JSON format
    const data = await response.json();
    setBestProducts(data.data.best);
    setTopProducts(data.data.top_products_all);
    setProducts(data.data.products)
    
    const data2 = {
      message: messages,
      token_usage: 0
    }
    // Push the AI response to the database
    push(chatRef, {
      user: "gpt",
      message: messages,
      best: data.data.best,
      top: data.data.top_products_all,
      products: data.data.products,
      timestamp: Date.now(),
    })
      .then(() => {})
      .catch((error) => {
        console.log("The write failed...", error);
      });

    // Push the token usage to the database
    update(tokenRef, {
      total_tokens_used: increment(data2.token_usage),
    })
      .then(() => {})
      .catch((error) => {
        console.log("The write failed...", error);
      });

    // Set the chat log with the AI response
    setChatLog([...chatLogNew, { user: "gpt", message: `${data2.message}` }]);
    setIsLoading(false);
  }

  if (!localStorage.getItem('login')) {
    window.location = "/"
  }

  // Get users subscription data
  const loadSubscription = async () => {
    // Get the current user
    const sub = auth.currentUser;
    // Get the reference to the user's subscriptions
    const ref = await getDocs(
      collection(firestoreDB, `customers/${sub.uid}/subscriptions`)
    );

    // Set subscription details in state
    ref.forEach(async (doc) => {
      setSubscription({
        role: doc.data().role,
        current_period_end: doc.data().current_period_end,
        current_period_start: doc.data().current_period_start,
        ended_at: doc.data().ended_at,
        status: doc.data().status,
      });
    });
  };

  const renderCard = (product, index) => {
    return <Grid item xs={1} sm={2} md={2} lg={1} key={index}>
    <Card sx={{ maxWidth: 300 }} sm={{ maxWidth: 240 }} md={{ maxWidth: 240 }} lg={{ maxWidth: 240 }}>
      <CardMedia
        sx={{ width: "100%", height: 230 }}
        sm={{ width: "100%", height: 230 }}
        md={{ width: "100%", height: 230 }}
        lg={{ width: "100%", height: 230 }}
        image={product.thumbnail}
        title={product.title}
        style={{marginTop: 50, marginBottom: 20, maxWidth:"100%", maxHeight:"100%"}}
      />
      <CardContent  style={{backgroundColor: "#353740"}}>
        <Typography gutterBottom variant="subtitle2" component="div" color="white">
          <a href={product.url} target="_blank" style={{color: '#e3e3e3'}}>
            {product.title.slice(0, 60)}
          </a>
        </Typography>
        <Typography variant="body2" color="#ffd300">
        Rating: {product.reviews.rating} ({product.reviews.total_reviews} reviews)
        </Typography>
        <Typography variant="h6" color="white">
        {product.price.current_price} {product.price.currency}
        </Typography>
      </CardContent>
      <CardActions style={{backgroundColor: "#353740"}}> 
        <Button size="small" onClick={()=> {navigator.clipboard.writeText(product.url)}} style={{color: 'white'}}>Copy link</Button>
      </CardActions>
    </Card>
    </Grid>
  } 
  const setConversation = (userId, message) => {
    // Get the database reference for each conversation
    get(ref(db, `messages/${userId}/${message.conversationId}`)).then(
      (snapshot) => {
        // Check if data exists for the conversation
        if (snapshot.exists()) {
          // Get the first message of the conversation
          const firstMessage = Object.values(snapshot.val())[1];
          setBestProducts(firstMessage.best);
          setTopProducts(firstMessage.top_products_all);
          setProducts(firstMessage.products)
        } else {
          console.log("No data available");
        }
      }
    );
    console.log(message);
  }; 

  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawerR = () => {
    setShowDrawer(!showDrawer);
    console.log(!showDrawer)
  };

  return (
    <div className="App">
      {/* Side menu (not visible on mobile) */}    
      <aside className={`side-menu ${showDrawer ? "show" : "hidden"}`}>
        <img src="/logo192.png" style={{maxWidth: 130}}></img>
        <NewChatButton handleNewChat={handleNewChat} isLoading={isLoading} />
        {/* Display message history if user is logged in */}
        {user && (
          <MessageHistory
            userId={user.uid}
            conversationId={conversationId}
            setConversation={setConversation}
            db={db}
          />
        )}
        <div className="white-buttons">
          <ClearConversations user={user} db={db} />
          <BugReportModal user={user} db={db} />
        </div>

        {/* <ModeChanger
          user={user}
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          isLoading={isLoading}
          handleNewChat={handleNewChat}
        /> */}
        {/* Displays users free trial status */}
        {/* {<FreeTrial />} */}

        {/* Takes user to the store, only displayed if user is logged in */}
        {/* <StoreButton user={user} /> */}

        {/* Login/logout buttons */}
        {!authLoading && (
          <GoogleAuthButtons
            user={user}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        )}
      </aside>

      {/* Chat box */}
      <section className="chat-box">
      <AppBar position="static" style={{backgroundColor: "black"}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerR}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <Button color="inherit"onClick={handleLogout} >logout</Button>
        </Toolbar>
      </AppBar>
      {/* <div>
        <React.Fragment key={'left'}>
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          >
            {list('left')}
          </Drawer>
        </React.Fragment>
      </div> */}

        {/* <nav className="navbar">
          <div className="logo">
            <a href="/">ChatGPT</a>
          </div>
          <div className="menu">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/">About</a></li>
              <li><a href="/">Services</a></li>
              <li><a href="/">Contact</a></li>
            </ul>
          </div>
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav> */}
      
        {/* If the user is subscribed or has trial display the chat input form */}
        {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          subscription?.status !== "incomplete") ? (
          <ChatInputForm
            input={input}
            setInput={setInput}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            user={user}
            handleLogin={handleLogin}
            selectedModel={selectedModel}
          />
        ) : null}

        {/* New chat button for mobile only */}
        <div className="mobile-new-chat-button">
          <NewChatButton isLoading={isLoading} handleNewChat={handleNewChat} />
        </div>
        {/* Only display hero if theres no chats and if user isn't rate limited */}
        {/* {chatLog.length === 0 && !isRateLimited && <Hero />} */}
        {/* Store button for mobile only */}
        {/* <div className="mobile-store-button">
          {chatLog.length === 0 && <StoreButton user={user} />}
        </div> */}
        {/* Chat log (the actual messages) */}
        {!isRateLimited && (
          <div className="chat-log">
            {isLoading === true && (
                <div className="circular-progress">
                  <CircularProgress style={{ color: "#b3befe" }} />
                </div>
            )}
            {bestProducts.length > 0 ? <div className="response-separator"><h2>Best</h2></div> : <></>}
            <Grid container spacing={{ xs: 1, sm: 2, md: 2}} columns={{ xs: 1, sm: 2, md: 6, lg: 4 }}>
              {bestProducts.map((product, index) => (
                renderCard(product, index)
              ))}
            </Grid>
            {products.length > 0 ? <div className="response-separator"style={{marginTop: 120}}><h2>All products</h2></div> : <></>}
            <Grid container spacing={{ xs: 1, sm: 2, md: 2}} columns={{ xs: 1, sm: 2, md: 6, lg: 4 }}>
              {products.map((product, index) => (
                renderCard(product, index)
              ))}
            </Grid>
         
    </div>
        )}
        {/* Error messages */}
        {isRateLimited && <RateLimitError />}
        {responseFailed && <ResponseFailedError />}

        {/* 
          Login button for mobile only 
          Right now its set up to only display the login button need to rework
          the ui for mobile 
        */}
        {/* {!user && !authLoading && (
          <div className="mobile-login-button">
            <GoogleAuthButtons
              user={user}
              handleLogin={handleLogin}
              handleLogout={handleLogout}
            />
          </div>
        )} */}
        {/* If the user is not signed in display sign up heading */}
        {/* {!user && !authLoading && (
          <SignUpHeading handleLogin={handleLogin} isLoggingIn={isLoggingIn} />
        )} */}
        {/* If user is subscribed or has trial display temperature slider */}
        {/* {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          selectedModel === "text-davinci-003" &&
          subscription?.status !== "incomplete") ? (
          <TemperatureSlider setTemperature={setTemperature} user={user} />
        ) : null}  */}
        {/* If user is in code mode show coding mode guide */}
        {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          user &&
          chatLog.length === 0) ? (
          <CodingModeGuide selectedModel={selectedModel} />
        ) : null}
        {/* If the user isn't subscribed display the visit store heading */}
        {/* {subscription?.role !== "premium" && user && !hasTrial && (
          <NotSubscribedHeading />
        )} */}
        {/* Loading spinner when user is logging in */}
        {authLoading && <CircularProgress style={{ color: "#b3befe" }} />}

        <Disclaimer />
      </section>
    </div>
  );
}

export default MainPage;
