import "./Home.css";
import React from 'react';
import { useState, useEffect } from "react";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithRedirect,
    signInWithPopup,
    getRedirectResult,
    signOut,
    onAuthStateChanged
  } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";


function Home({ app, db }) {
  const [user, setUser] = useState(null);
  const provider = new GoogleAuthProvider(app);
  const auth = getAuth(app);
  const firestoreDB = getFirestore(app);
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [isRateLimited, setIsRateLimited] = useState(false);

  // Redirect user to google login
  function handleLogin() {
    setIsLoggingIn(true);
    signInWithPopup(auth, provider);
    getRedirectResult(auth)
      .then(() => {
        console.log("Signed In");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Log user out, clear old messages and input
  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Logged out");
        localStorage.removeItem('login')
        localStorage.removeItem('country')
        setIsLoading(false);
        setIsRateLimited(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // When the authentication state changes (user logging in/out)
    onAuthStateChanged(auth, (user) => {        
        setAuthLoading(false);
        setUser(user);
        localStorage.setItem('login', Date())
        if (user) {
            window.location ='/app'
        }
    });
  }, []);

  return (
    <>
        <header className="header" id="header">
            <nav className="navbar container">
                <div className="brand">
                    <img src="https://i.ibb.co/1TDFC9K/logo-deepshop.png" alt="deepshop.ai" />
                </div>

                <div className='absolutePosition'>
                  {!user && !authLoading && (
                              <button className="btn btn-darken btn-inline" onClick={handleLogin}>
                                  Register <i className="bx bx-right-arrow-alt"></i>
                              </button>
                          )}
                  {user && (<button className="btn btn-darken btn-inline" onClick={handleLogout}>
                              Logout <i className="bx bx-right-arrow-alt"></i>
                          </button>)}
                  {!user && !authLoading && (<a onClick={handleLogin} className="menu-block">Login</a>)}
                  {user && (<a onClick={handleLogout} className="menu-block">Logout</a>)}  

                </div> 
            </nav>
        </header>
        <main className="main">
            <section className="section banner banner-section">
                <div className="container banner-column">
                    <img className="banner-image" src="https://i.ibb.co/G26TJK8/img-tech.png" alt="banner" />
                    <div className="banner-inner">
                        <h1 className="heading-xl">Transform Your Amazon Search with AI: Find Your Ideal Product Almost Instantly!</h1>
                        <p className="paragraph">
                          Need the Perfect Product Fast? Deepshop Finds the Best Amazon Options Instantly for Any Search!
                        </p>
                </div>
                </div>
                
            </section>
        </main>
    </>
  );
}

export default Home;
