import React from "react";
import "./Disclaimer.css";

function Disclaimer() {
  return (
    <div className="disclaimer-container">
      <p className="disclaimer-text">
        deepShop 2023 by <a href="mailto:jair@addabra.com">ADDABRA</a> 
      </p>
    </div>
  );
}

export default Disclaimer;
