import React, { Suspense } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import { CircularProgress } from "@mui/material";
import Home from "./Pages/Home";
const Error404Page = React.lazy(() => import("./Pages/Error404Page"));
const StorePage = React.lazy(() => import("./Pages/StorePage"));

const firebaseConfig = {
  apiKey: "AIzaSyClU27dvz22QdZf0lFtxnJzFMDmbAqXreU",
  authDomain: "deepshop-81732.firebaseapp.com",
  projectId: "deepshop-81732",
  storageBucket: "deepshop-81732.appspot.com",
  messagingSenderId: "845417297782",
  appId: "1:845417297782:web:9b5dbb58207d5b20432c57",
  measurementId: "G-VBDSF1B93X",
  databaseURL: "https://deepshop-81732-default-rtdb.firebaseio.com/"
};

const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV !== "test") {
  const analytics = getAnalytics(app);
}
export const db = getDatabase(app);

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <CircularProgress
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "#b3befe",
            }}
          />
        }
      >
        <Routes>
          <Route path="/" element={<Home app={app} db={db} />} />
          <Route path="/app" element={<MainPage app={app} db={db} />} />
          <Route path="/store" element={<StorePage app={app} />} />

          <Route path="*" element={<Error404Page />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
